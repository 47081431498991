import { createAsync } from '@solidjs/router';
import { Suspense, Show } from 'solid-js';
import { ReservationCtx } from '../../providers/reservation';
import { Error404, gql } from '../../graphql';
import { cachedQuery } from '../../graphql/cached-get';
import type { RouteSectionProps } from '@solidjs/router';

export default function ReservationIdPage(props: RouteSectionProps) {
	const reservation = createAsync(() => getReservation({ id: props.params.reservationId! }), { deferStream: true });
	return (
		<Suspense>
			<Show when={reservation.latest}>
				<ReservationCtx.Provider value={reservation}>{props.children}</ReservationCtx.Provider>
			</Show>
		</Suspense>
	);
}

const reservationQuery = gql(`
query reservationInformation($id: String!, $rewardType: RedeemableRewardType) {
  reservation(id: $id) {
		id
    teeTimeId
    ownerId
    state
    playerCount
    holeCount
    includesCart
		checkInEnabled
    isCheckInWindow
		isCancelWindow
		isPastStartTime
		shareInviteUrl
		...ReservationHeader
		calendarEvent {
			google
			yahoo
			outlook
			office365
			ics
		}
    dayTime { day { year, month, day }, time { hour, minute } }
    cancelDayTime { day { year, month, day }, time { hour, minute } }
		cancelFee { ...Currency }
    isToday
		allowsGuestPasses
		guestPasses { ...ShareGuestPass }
		guestPassDiscount { value, displayValue }
		facility {
			name
			...FacilityLocation
			timezone
 			metadata {
    		hero { url }
 			  address {
 			    street
 			    street2
 			    city
 			    state
 			    country
 			    postalCode
 			  }
 			  phone
 			  website
 			}
		}
		course { name }
    users {
			id
			guestPass { ...ShareGuestPass }
      user {
				id
        firstName
        lastName
				troonAccessProductType
      }
      state
      isCheckedIn
    }
  }
	accessUpsell: troonAccessReservationUpsell(reservationId: $id) {
		...ReservationAccessUpsell
	}
	reservationPaymentInfo(reservationId: $id, rewardType: $rewardType) {
		rewardPointsEarned
		receipt { ...PaymentInfo }
	}
	guestPasses {
		available { ...ShareGuestPass }
		pending { ...ShareGuestPass }
		accepted { ...ShareGuestPass }
	}
}`);

const getReservation = cachedQuery(reservationQuery, {
	onError: (error) => {
		if (error.graphQLErrors[0]?.message.toLowerCase().includes('does not exist')) {
			throw new Error404(error.graphQLErrors[0]);
		}
	},
});
